import { getMappedDocumentsObject } from 'components/organisms/documents-table/utils';

export interface DocumentsTableState {
  searchInput: string;
  documentsMappedOnIndex: Record<number, string>;
  slicedDocuments: Record<string, unknown>[];
  rowSelection: string[];
  pageSize: number;
  page: number;
  count: number;
  downloadAllUrl: string | null;
  downloadMultipleUrl: string | null;
}

export enum DIRECTION {
  NEXT = 'next',
  PREVIOUS = 'previous',
}

export const DEFAULT_OPTIONS = [10, 20, 30, 40, 50];

export type DocumentsTableAction =
  | { type: 'SET_SEARCH_INPUT'; payload: string }
  | { type: 'SET_DOCUMENTS_DATA'; payload: readonly Record<string, unknown>[] }
  | { type: 'SET_ROW_SELECTION'; payload: string }
  | { type: 'SET_COUNT'; payload: number }
  | { type: 'SET_PAGE'; payload: string }
  | { type: 'SET_PAGE_SIZE'; payload: number }
  | { type: 'SET_DATA'; payload: { downloadAllUrl: string; downloadMultipleUrl: string; count: number } }
  | { type: 'SET_DOWNLOAD_URL'; payload: { downloadAllUrl: string; downloadMultipleUrl: string } }
  | { type: 'HANDLE_ROW_SELECTION'; payload: string }
  | { type: 'RESET_ROW_SELECTION' }
  | { type: 'SELECT_ALL_ROW_SELECTION' };

const rowSelect = (rowSelection: string[], id: string) => {
  if (rowSelection.includes(id)) {
    return rowSelection.filter(item => item !== id);
  }

  return [...rowSelection, id];
};

export const documentsTableInitialState: DocumentsTableState = {
  searchInput: '',
  slicedDocuments: [],
  rowSelection: [],
  pageSize: 10,
  page: 1,
  count: 0,
  downloadAllUrl: null,
  downloadMultipleUrl: null,
  documentsMappedOnIndex: {},
};

export function documentsTableStateReducer(
  state: DocumentsTableState,
  action: DocumentsTableAction,
): DocumentsTableState {
  switch (action.type) {
    case 'SET_SEARCH_INPUT':
      return {
        ...state,
        searchInput: action.payload,
      };
    case 'SET_DOCUMENTS_DATA':
      const slicedDocuments = action.payload.slice(
        state.page * state.pageSize - state.pageSize,
        state.page * state.pageSize,
      );
      const documentsMappedOnIndex = getMappedDocumentsObject(slicedDocuments);

      return {
        ...state,
        slicedDocuments: slicedDocuments,
        documentsMappedOnIndex: documentsMappedOnIndex,
      };
    case 'SET_COUNT':
      return {
        ...state,
        count: action.payload,
      };
    case 'SET_PAGE':
      const newPage = action.payload === DIRECTION.NEXT ? state.page + 1 : state.page - 1;
      return {
        ...state,
        page: newPage,
      };
    case 'SET_PAGE_SIZE':
      return {
        ...state,
        pageSize: action.payload,
      };
    case 'SET_ROW_SELECTION':
      const newRowSelection = rowSelect(state.rowSelection, action.payload);
      return {
        ...state,
        rowSelection: newRowSelection,
      };
    case 'SET_DATA':
      return {
        ...state,
        ...action.payload,
      };
    case 'RESET_ROW_SELECTION':
      return {
        ...state,
        rowSelection: [],
      };
    case 'SELECT_ALL_ROW_SELECTION':
      const all = state.slicedDocuments.map(document => document.id) as string[];
      return {
        ...state,
        rowSelection: all,
      };
    case 'HANDLE_ROW_SELECTION':
      const rowIndex = action.payload;
      if (state.rowSelection.includes(rowIndex)) {
        const copyRowSelection = [...state.rowSelection];
        const removeIndex = copyRowSelection.indexOf(rowIndex);
        copyRowSelection.splice(removeIndex, 1);
        return {
          ...state,
          rowSelection: copyRowSelection,
        };
      } else {
        return {
          ...state,
          rowSelection: [...state.rowSelection, rowIndex],
        };
      }
    default:
      throw new Error('Unhandled action type');
  }
}
