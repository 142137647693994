import Button from 'components/atoms/button/button';
import Typography from 'components/atoms/typography/typography';
import { FormattedMessage } from 'react-intl';
import { Spinner } from 'components/atoms/spinner/spinner';
import TableSelect from 'components/molecules/table-select/table-select';
import React, { FC, useCallback, useContext, useState } from 'react';
import Icon from 'components/atoms/icon/icon';
import './documents-table.scss';
import Block from 'components/utilities/block/block';
import { authenticationService } from 'services/authentication/authentication-service';
import DocumentsTableStateContext from 'components/organisms/documents-table/documents-table-state-context';
import { DEFAULT_OPTIONS, DIRECTION } from 'components/organisms/documents-table/documents-table-state-reducer';

export const DocumentsTableFooter: FC = () => {
  const { state, dispatch } = useContext(DocumentsTableStateContext);

  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloadDocuments = useCallback(async () => {
    setIsDownloading(true);

    const token = await authenticationService.tokenAsync();

    const requestOptions: RequestInit = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    const response =
      state.rowSelection.length === 0
        ? await fetch(`${state.downloadAllUrl}`, { ...requestOptions, method: 'GET' })
        : await fetch(`${state.downloadMultipleUrl}`, {
            ...requestOptions,
            method: 'POST',
            body: JSON.stringify(state.rowSelection),
          });

    const blob = await response.blob();
    saveAs(blob, 'dokumenter.zip');

    setIsDownloading(false);
  }, [state.downloadAllUrl, state.downloadMultipleUrl, state.rowSelection]);

  const saveAs = (blob: Blob, fileName: string) => {
    const a = document.createElement('a');
    a.download = fileName;
    a.rel = 'noopener';
    a.href = URL.createObjectURL(blob);
    setTimeout(() => URL.revokeObjectURL(a.href), 60 * 1000);
    setTimeout(() => a.click(), 0);
  };

  const onPageChange = (direction: DIRECTION) => {
    dispatch({ type: 'SET_PAGE', payload: direction });
  };

  const onRowsPerPageChange = (val: number) => {
    dispatch({ type: 'SET_PAGE_SIZE', payload: val });
  };

  const renderLeftArrow = () => {
    if (state.page === 1) {
      return <span className="whitespace">&nbsp;</span>;
    } else {
      return <Icon name={'ArrowLeft'} className="buttonIcon" onClick={() => onPageChange(DIRECTION.PREVIOUS)} />;
    }
  };

  const renderRightArrow = () => {
    if (state.pageSize >= state.count || state.page * state.pageSize >= state.count) {
      return <span className="whitespace">&nbsp;</span>;
    } else {
      return (
        <Icon name={'ArrowRight'} className="table__footer-buttonIcon" onClick={() => onPageChange(DIRECTION.NEXT)} />
      );
    }
  };

  return (
    <tfoot>
      <tr>
        <td colSpan={6}>
          <Block className="table__footer" flex spaceBetween positionRelative>
            <Button
              disabled={isDownloading}
              className="same button"
              onClick={handleDownloadDocuments}
              version="primary"
            >
              <Typography tag="span">
                <FormattedMessage defaultMessage="Download" id="Documents.Download" />{' '}
                {state.rowSelection.length > 0 ? (
                  <FormattedMessage defaultMessage="Selected" id="Documents.Selected" />
                ) : (
                  <FormattedMessage defaultMessage="all" id="Documents.All" />
                )}
              </Typography>
              {isDownloading && <Spinner />}
            </Button>

            <TableSelect
              currentPage={state.page}
              onChange={onRowsPerPageChange}
              options={DEFAULT_OPTIONS}
              pageSize={state.pageSize}
              totalItems={state.count || 0}
            />
            <div className="footer__navigation">
              {renderLeftArrow()}
              {renderRightArrow()}
            </div>
          </Block>
        </td>
      </tr>
    </tfoot>
  );
};
