import { TableSkeleton } from 'components/organisms/documents-table/table-skeleton';
import { DocumentsTableRow } from 'components/organisms/documents-table/documents-table-row';
import EmptyTableBody from 'components/organisms/documents-table/empty-table-body';
import React, { FC, useContext, useEffect } from 'react';
import { graphql, usePaginationFragment } from 'react-relay';
import { useNodes } from 'hooks/useNodes';
import DocumentsTableStateContext from 'components/organisms/documents-table/documents-table-state-context';
import { favoritePageDocumentsBody_CasePotentialBuyer$key } from '__generated__/favoritePageDocumentsBody_CasePotentialBuyer.graphql';

const favoritePageDocumentsBody_CasePotentialBuyer = graphql`
  fragment favoritePageDocumentsBody_CasePotentialBuyer on CasePotentialBuyer
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 10 }
    cursor: { type: "String" }
    searchText: { type: "String" }
  )
  @refetchable(queryName: "documentsPaginationQuery_CasePotentialBuyer") {
    baseCase {
      publicDocumentAllDownloadUrl
      publicDocumentMultipleDownloadUrl
      publicDocumentConnection(first: $count, after: $cursor, searchText: $searchText)
        @connection(key: "documents__publicDocumentConnection") {
        edges {
          node {
            id
            ...documentsTableRowFragment
          }
        }
        totalCount
      }
    }
  }
`;

type Props = {
  documentFragment: favoritePageDocumentsBody_CasePotentialBuyer$key;
};
export const FavoritePageDocumentsBody: FC<Props> = ({ documentFragment }) => {
  const { data, refetch, loadNext, hasNext, isLoadingNext } = usePaginationFragment(
    favoritePageDocumentsBody_CasePotentialBuyer,
    documentFragment,
  );

  const { state, dispatch } = useContext(DocumentsTableStateContext);

  const documentNodes = useNodes(data?.baseCase?.publicDocumentConnection?.edges);
  const slicedDocuments = documentNodes.slice(
    state.page * state.pageSize - state.pageSize,
    state.page * state.pageSize,
  );

  useEffect(() => {
    refetch(
      {
        after: null,
        first: state.pageSize,
        searchText: state.searchInput,
        id: data.id,
      },
      { fetchPolicy: 'store-and-network' },
    );
  }, [state.searchInput, state.pageSize, refetch, data.id]);

  useEffect(() => {
    if (documentNodes) {
      dispatch({ type: 'SET_DOCUMENTS_DATA', payload: documentNodes });
    }
    if (data.baseCase) {
      const payload = {
        downloadAllUrl: data.baseCase?.publicDocumentAllDownloadUrl ?? '',
        downloadMultipleUrl: data.baseCase?.publicDocumentMultipleDownloadUrl ?? '',
        count: data.baseCase?.publicDocumentConnection?.totalCount ?? 0,
      };
      dispatch({
        type: 'SET_DATA',
        payload: payload,
      });
    }
  }, [data, dispatch, documentNodes]);

  useEffect(() => {
    if (state.page && hasNext) {
      loadNext(state.pageSize, { UNSTABLE_extraVariables: { id: data.id } });
    }
  }, [loadNext, hasNext, state.page, state.pageSize, data.id]);

  const handleRowSelection = (id: string | undefined) => {
    if (typeof id !== 'string') {
      return false;
    }

    dispatch({
      type: 'HANDLE_ROW_SELECTION',
      payload: id,
    });
  };

  if (isLoadingNext) {
    return <TableSkeleton />;
  } else {
    return (
      <tbody className="table__body">
        {slicedDocuments.length > 0 &&
          slicedDocuments.map(document => (
            <DocumentsTableRow
              rowSelection={state.rowSelection}
              setRowSelection={handleRowSelection}
              documentFragment={document}
              key={document.id}
            />
          ))}
        {slicedDocuments.length === 0 && <EmptyTableBody searchString={state.searchInput} />}
      </tbody>
    );
  }
};
