import { mainNavigationNotificationsRefreshQuery } from '__generated__/mainNavigationNotificationsRefreshQuery.graphql';
import { notifications_fragment$key } from '__generated__/notifications_fragment.graphql';
import { subscribeClientMutation } from '__generated__/subscribeClientMutation.graphql';
import { unsubscribeClientMutation } from '__generated__/unsubscribeClientMutation.graphql';
import Button from 'components/atoms/button/button';
import Typography from 'components/atoms/typography/typography';
import { useViewer } from 'contexts/viewerContext';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { useNodes } from 'hooks/useNodes';
import { FC, Fragment, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useMutation, usePaginationFragment } from 'react-relay';
import { subscribeClient } from 'services/relay/mutations/subscribeClient';
import { unsubscribeClient } from 'services/relay/mutations/unsubscribeClient';
import { arrayBufferToBase64 } from 'utils/arrayBufferToBase64';
import { requestNotificationPermission } from 'utils/requestNotificationPermission';
import { NotificationComponent } from './components/notification-component';
import { NoNoficationData } from './no-notification-data';
import './notifications.scss';
import Column from 'components/utilities/column/column';
import Icon from 'components/atoms/icon/icon';

export interface NotificationsProps {
  onCloseNotifications: () => void;
  onNotificationClick: (id: string) => void;
  fragment: notifications_fragment$key | null;
  handleToggle: () => void;
  setNotificationsEnabled: (enable: boolean) => void;
  notificationsEnabled: boolean;
}

const notificationFragmentQuery = graphql`
  fragment notifications_fragment on Customer
  @argumentDefinitions(first: { type: "Int", defaultValue: 12 }, after: { type: "String" })
  @refetchable(queryName: "mainNavigationNotificationsRefreshQuery") {
    notificationConnection(first: $first, after: $after) @connection(key: "mainNavigation_notificationConnection") {
      edges {
        node {
          id
          __typename
          ...notificationComponent_fragment
        }
      }
    }
  }
`;

const Notifications: FC<NotificationsProps> = props => {
  const { data, hasNext, loadNext } = usePaginationFragment<
    mainNavigationNotificationsRefreshQuery,
    notifications_fragment$key
  >(notificationFragmentQuery, props.fragment);
  const notifications = useNodes(data?.notificationConnection?.edges);

  const { setNotificationsEnabled, notificationsEnabled } = props;

  const ref = useRef<HTMLLIElement>(null);
  const entry = useIntersectionObserver(ref, {});
  const isVisible = !!entry?.isIntersecting;

  const viewer = useViewer();
  const featureToggles = viewer?.featureToggle;
  const isPushNotificationsEnabledAndSupported =
    !!featureToggles?.isWebPushNotificationsEnabled && 'Notification' in window;
  const isPushNotificationsEnabledAndNotSupported =
    !!featureToggles?.isWebPushNotificationsEnabled && !('Notification' in window);

  const [subscribe] = useMutation<subscribeClientMutation>(subscribeClient);
  const [unsubscribe] = useMutation<unsubscribeClientMutation>(unsubscribeClient);

  useEffect(() => {
    if (isVisible && hasNext && data?.id) {
      loadNext(12, { UNSTABLE_extraVariables: { id: data.id } });
    }
  }, [isVisible, hasNext, loadNext, data?.id]);

  const setCorrectRef = (index: number) => {
    if (index === notifications.length - 1) {
      return ref;
    }
    return null;
  };

  const handleSubscribeNotifications = async () => {
    //prompts user for native device notification prompt
    const result = await requestNotificationPermission();
    if (result === 'neutral') return;

    if (result === 'denied') {
      // prompt tooltip to tell user they have blocked notifications
      props.handleToggle();
      return;
    }

    if (result !== 'granted') {
      navigator.permissions.query({ name: 'notifications' }).then(result => {
        if (result.state === 'denied') {
          // Prompt user again with info that they need to remove from blocked
          props.handleToggle();
          return;
        }
      });
    }

    const registration = await navigator.serviceWorker.ready;
    const pushSubscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: viewer?.vapidPublicKey,
    });

    subscribe({
      variables: {
        input: {
          endpoint: pushSubscription.endpoint,
          p256Dh: arrayBufferToBase64(pushSubscription.getKey('p256dh')),
          auth: arrayBufferToBase64(pushSubscription.getKey('auth')),
        },
      },
    });
    setNotificationsEnabled(true);
  };

  const handleUnsubscribeNotifications = async () => {
    //Unsubscribe from messages. There is no way to programmtically revoke the permission to show notifications.
    const registration = await navigator.serviceWorker.ready;
    const pushSubscription = await registration.pushManager.getSubscription();
    const result = await pushSubscription?.unsubscribe();

    if (result && pushSubscription) {
      unsubscribe({
        variables: {
          input: {
            endpoint: pushSubscription.endpoint,
            p256Dh: arrayBufferToBase64(pushSubscription.getKey('p256dh')),
            auth: arrayBufferToBase64(pushSubscription.getKey('auth')),
          },
        },
      });
      setNotificationsEnabled(false);
    }
  };

  return (
    <div className="notifications">
      <div className="notifications__heading">
        <Typography tag="span" tagStyle="headlineMedium">
          <FormattedMessage defaultMessage={'Notifications'} id="Notifications.Headline" />
        </Typography>

        {isPushNotificationsEnabledAndSupported && (
          <div>
            {!notificationsEnabled ? (
              <Button onClick={handleSubscribeNotifications}>
                <FormattedMessage defaultMessage="Get push messages" id="Notifications.EnablePush" />
              </Button>
            ) : (
              <Button onClick={handleUnsubscribeNotifications}>
                <FormattedMessage defaultMessage="Disable push messages" id="Notifications.DisablePush" />
              </Button>
            )}
          </div>
        )}
      </div>
      <div className="push-notifications">
        {isPushNotificationsEnabledAndNotSupported && (
          <Fragment>
            <Column className={'pwa-buttons'}>
              <Typography>
                <FormattedMessage id="pwa.clickOnTheIcon.text" defaultMessage="Click on the icon" /> (
                <Icon name={'Share'} className="icon" />){' '}
                <FormattedMessage
                  id="pwa.below.notifications"
                  defaultMessage="below and choose “Add to home screen“ to be notified about the newest updates and messages"
                />
              </Typography>
            </Column>
          </Fragment>
        )}
        {isPushNotificationsEnabledAndSupported && !notificationsEnabled && (
          <Typography tag={'small'} subjectColor={'gray'}>
            <FormattedMessage
              defaultMessage="To keep you informed with the latest news and features, we recommend allowing 'push notifications' from our website. This grants you direct access to updates notifications, ensuring you never miss out on anything important."
              id="User.AllowPushNotifications"
            />
          </Typography>
        )}
      </div>

      {!data ? (
        <NoNoficationData />
      ) : (
        <div className="notifications__new">
          <ul className="notifications__list">
            {notifications.map((item, index) => (
              <li
                key={item.id}
                ref={setCorrectRef(index)}
                className="notifications__item"
                onClick={() => {
                  props.onCloseNotifications();
                  props.onNotificationClick(item.id);
                }}
              >
                <NotificationComponent notification={item} />
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Notifications;
