//==============================//
// AUTOGENERATED - DO NOT EDIT //
//============================//
//  UPDATE BY RUNNING TYPES  //
//==========================//

/* eslint-disable */
/* prettier-ignore-start*/

export const ActivityNote = "ActivityNote";

export const ActivityNotification = "ActivityNotification";

export const ActivityType = "ActivityType";

export const Address = "Address";

export const AgricultureSearchProfile = "AgricultureSearchProfile";

export const AnalyticsData = "AnalyticsData";

export const AnalyticsReport = "AnalyticsReport";

export const AnalyticsReportPercentage = "AnalyticsReportPercentage";

export const AppointmentActivity = "AppointmentActivity";

export const AppointmentActivityConnection = "AppointmentActivityConnection";

export const AppointmentActivityEdge = "AppointmentActivityEdge";

export const AppointmentActivityType = "AppointmentActivityType";

export const BaseCase = "BaseCase";

export const BaseCaseMainOffer = "BaseCaseMainOffer";

export const Building = "Building";

export const BuildingInclArea = "BuildingInclArea";

export const BusinessBuilding = "BusinessBuilding";

export const BusinessCase = "BusinessCase";

export const BusinessRentalCase = "BusinessRentalCase";

export const BusinessRentalSearchProfile = "BusinessRentalSearchProfile";

export const BusinessSaleSearchProfile = "BusinessSaleSearchProfile";

export const BusinessSalesCase = "BusinessSalesCase";

export const BusinessSalesPresentation = "BusinessSalesPresentation";

export const BusinessValuationCase = "BusinessValuationCase";

export const CaseAnnounced = "CaseAnnounced";

export const CaseAreas = "CaseAreas";

export const CaseMarketingCampaignStatistics = "CaseMarketingCampaignStatistics";

export const CasePotentialBuyer = "CasePotentialBuyer";

export const CasePromotion = "CasePromotion";

export const CaseRelation = "CaseRelation";

export const ConfigurableTextSection = "ConfigurableTextSection";

export const Consent = "Consent";

export const ContractOfRentInterface = "ContractOfRentInterface";

export const ContractOfSaleBusiness = "ContractOfSaleBusiness";

export const ContractOfSaleInterface = "ContractOfSaleInterface";

export const ContractOfSalePrivate = "ContractOfSalePrivate";

export const CounselingCase = "CounselingCase";

export const CounselingPropertyCase = "CounselingPropertyCase";

export const Country = "Country";

export const Customer = "Customer";

export const CustomerAddress = "CustomerAddress";

export const CustomerConsent = "CustomerConsent";

export const CustomerContactInfo = "CustomerContactInfo";

export const CustomerEmail = "CustomerEmail";

export const CustomerFavoriteCaseConnection = "CustomerFavoriteCaseConnection";

export const CustomerFavoriteCaseEdge = "CustomerFavoriteCaseEdge";

export const CustomerPhone = "CustomerPhone";

export const Document = "Document";

export const DocumentAccessGrantedNotification = "DocumentAccessGrantedNotification";

export const DocumentConnection = "DocumentConnection";

export const DocumentEdge = "DocumentEdge";

export const DocumentSignature = "DocumentSignature";

export const DocumentSigningNotification = "DocumentSigningNotification";

export const DocumentType = "DocumentType";

export const Employee = "Employee";

export const EmployeeOffer = "EmployeeOffer";

export const Error = "Error";

export const FeatureToggle = "FeatureToggle";

export const INotificationBadgeCount = "INotificationBadgeCount";

export const IThreadBadgeCount = "IThreadBadgeCount";

export const IncreasePerYear = "IncreasePerYear";

export const LeadConsentGraphType = "LeadConsentGraphType";

export const LeadInterface = "LeadInterface";

export const LeadNotification = "LeadNotification";

export const LeadOpenHouse = "LeadOpenHouse";

export const LeadPresentation = "LeadPresentation";

export const LeadSalesMaterialWithContact = "LeadSalesMaterialWithContact";

export const LeadTypeGraphType = "LeadTypeGraphType";

export const MarketDataCase = "MarketDataCase";

export const MarketDataCasePrimaryImage = "MarketDataCasePrimaryImage";

export const MarketingCampaign = "MarketingCampaign";

export const MarketingCampaignAdTimelineItem = "MarketingCampaignAdTimelineItem";

export const MarketingCampaignInteraction = "MarketingCampaignInteraction";

export const MarketingCampaignInteractionStatistics = "MarketingCampaignInteractionStatistics";

export const MarketingCampaignInteractionsStatistics = "MarketingCampaignInteractionsStatistics";

export const MarketingCampaignLink = "MarketingCampaignLink";

export const MarketingCampaignTotals = "MarketingCampaignTotals";

export const MarketingPlacementsTimeline = "MarketingPlacementsTimeline";

export const Media = "Media";

export const MediaDataConnection = "MediaDataConnection";

export const MediaDataEdge = "MediaDataEdge";

export const MediaType = "MediaType";

export const Message = "Message";

export const MessageConnection = "MessageConnection";

export const MessageEdge = "MessageEdge";

export const MessageNotification = "MessageNotification";

export const Mutation = "Mutation";

export const MyPageMwSubscription = "MyPageMwSubscription";

export const MyPageSpot = "MyPageSpot";

export const MyPageSpotEntry = "MyPageSpotEntry";

export const Neighborhood = "Neighborhood";

export const NeighborhoodPin = "NeighborhoodPin";

export const NeighborhoodPinConnection = "NeighborhoodPinConnection";

export const NeighborhoodPinEdge = "NeighborhoodPinEdge";

export const Node = "Node";

export const NotificationInterface = "NotificationInterface";

export const NotificationInterfaceConnection = "NotificationInterfaceConnection";

export const NotificationInterfaceEdge = "NotificationInterfaceEdge";

export const Offer = "Offer";

export const OfferConnection = "OfferConnection";

export const OfferEdge = "OfferEdge";

export const OffmarketCase = "OffmarketCase";

export const OpenHouse = "OpenHouse";

export const OwnerCost = "OwnerCost";

export const PageInfo = "PageInfo";

export const ParticipantIdentity = "ParticipantIdentity";

export const Person = "Person";

export const Portfolio = "Portfolio";

export const PredefinedArea = "PredefinedArea";

export const PrivateBuilding = "PrivateBuilding";

export const PrivateRentalSearchProfile = "PrivateRentalSearchProfile";

export const PrivateSaleSearchProfile = "PrivateSaleSearchProfile";

export const Query = "Query";

export const QuestionnaireMetaData = "QuestionnaireMetaData";

export const RectifyCase = "RectifyCase";

export const RentalCase = "RentalCase";

export const RentalPresentation = "RentalPresentation";

export const RentalPresentationBusiness = "RentalPresentationBusiness";

export const RentalPresentationPrivate = "RentalPresentationPrivate";

export const RequestedDocumentNotification = "RequestedDocumentNotification";

export const RequestedDocumentType = "RequestedDocumentType";

export const Response = "Response";

export const SalesCase = "SalesCase";

export const SalesPresentation = "SalesPresentation";

export const SalesPresentationPrivate = "SalesPresentationPrivate";

export const SearchArea = "SearchArea";

export const SearchProfile = "SearchProfile";

export const SearchProfileExternalMatchedCase = "SearchProfileExternalMatchedCase";

export const SearchProfileExternalMatchedCaseConnection = "SearchProfileExternalMatchedCaseConnection";

export const SearchProfileExternalMatchedCaseEdge = "SearchProfileExternalMatchedCaseEdge";

export const SearchProfileInterfaceConnection = "SearchProfileInterfaceConnection";

export const SearchProfileInterfaceEdge = "SearchProfileInterfaceEdge";

export const SearchProfileMatchNotification = "SearchProfileMatchNotification";

export const SearchProfileMatchedCase = "SearchProfileMatchedCase";

export const SearchProfileMatchedCaseConnection = "SearchProfileMatchedCaseConnection";

export const SearchProfileMatchedCaseEdge = "SearchProfileMatchedCaseEdge";

export const SearchProfileMatchedCaseNotification = "SearchProfileMatchedCaseNotification";

export const ServiceIdentity = "ServiceIdentity";

export const Shop = "Shop";

export const StakeholderIdentity = "StakeholderIdentity";

export const StakeholderInOffer = "StakeholderInOffer";

export const StakeholderInRegistry = "StakeholderInRegistry";

export const TaskActivityConnection = "TaskActivityConnection";

export const TaskActivityEdge = "TaskActivityEdge";

export const TaskActivityGraph = "TaskActivityGraph";

export const Thread = "Thread";

export const ThreadConnection = "ThreadConnection";

export const ThreadEdge = "ThreadEdge";

export const ThreadParticipant = "ThreadParticipant";

export const TimelineActivity = "TimelineActivity";

export const TimelineActivityConnection = "TimelineActivityConnection";

export const TimelineActivityEdge = "TimelineActivityEdge";

export const TimelineActivityNote = "TimelineActivityNote";

export const TotalCosts = "TotalCosts";

export const ValuationCase = "ValuationCase";

export const Viewer = "Viewer";

export const ZipCode = "ZipCode";

/* prettier-ignore-end */
