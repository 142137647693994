import Typography from 'components/atoms/typography/typography';
import IndeterminateCheckbox from 'components/molecules/indeterminate-checkbox/indeterminate-checkbox';
import DocumentsTableStateContext from 'components/organisms/documents-table/documents-table-state-context';
import { FC, useContext, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import './documents-table.scss';

export const DocumentsTableHeader: FC = () => {
  const { state, dispatch } = useContext(DocumentsTableStateContext);

  const isAllSelected = useMemo(
    () => state.rowSelection.length === state.slicedDocuments.length && state.slicedDocuments.length !== 0,
    [state.rowSelection.length, state.slicedDocuments.length],
  );

  const handleRowSelection = () => {
    if (state.rowSelection.length === state.slicedDocuments.length) {
      dispatch({
        type: 'RESET_ROW_SELECTION',
      });
    } else {
      dispatch({
        type: 'SELECT_ALL_ROW_SELECTION',
      });
    }
  };

  return (
    <thead>
      <tr>
        <th className="documents-table__checkbox table__th" colSpan={1}>
          <IndeterminateCheckbox
            {...{
              checked: isAllSelected,
              indeterminate: false,
              onChange: handleRowSelection,
            }}
          />
        </th>
        <th className="documents-table__cell table__th">
          <div className="table__can-sort">
            <Typography>
              <FormattedMessage defaultMessage={'Type'} id={'Documents.Type'} />
            </Typography>
          </div>
        </th>
        <th className="hideMobile documents-table__cell table__th">
          <div className="table__can-sort">
            <Typography>
              <FormattedMessage defaultMessage={'Description'} id={'Documents.Description'} />
            </Typography>
          </div>
        </th>
        <th className="hideMobile documents-table__cell table__th">
          <div className="table__can-sort">
            <Typography>
              <FormattedMessage defaultMessage={'Status'} id={'Documents.Status'} />
            </Typography>
          </div>
        </th>
        <th className="documents-table__cell table__th">
          <div className="table__can-sort">
            <Typography>
              <FormattedMessage defaultMessage={'Visible per'} id={'Documents.Visible'} />
            </Typography>
          </div>
        </th>
        <th className="documents-table__cell table__th">
          <div>
            <Typography>
              <FormattedMessage defaultMessage={'Handling'} id={'Documents.Handling'} />
            </Typography>
          </div>
        </th>
      </tr>
      <tr>
        <th colSpan={6}>
          <hr className="table__hr" />
        </th>
      </tr>
    </thead>
  );
};
