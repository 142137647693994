import {
  openHouseImageOverlay_baseCase$data,
  openHouseImageOverlay_baseCase$key,
} from '__generated__/openHouseImageOverlay_baseCase.graphql';
import Button from 'components/atoms/button/button';
import Block from 'components/utilities/block/block';
import { useList } from 'hooks/useList';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { formatDate } from 'tools/string-formatters';
import { isAfter } from 'date-fns';

type OpenHouseImageOverlayProps = {
  fragment: openHouseImageOverlay_baseCase$key;
  handleOnClickOpenHouseModal: () => void;
};

const openHouseImageOverlayFragment = graphql`
  fragment openHouseImageOverlay_baseCase on BaseCase {
    openHouse(amount: 2) {
      registrationDeadline
      activityType {
        nameOnTimeline
        isRegistration
      }
      startDate
      isRegistred
    }
  }
`;

type OpenHouse = NonNullable<openHouseImageOverlay_baseCase$data['openHouse']>[number];
export const OpenHouseImageOverlay: FC<OpenHouseImageOverlayProps> = props => {
  const data = useFragment(openHouseImageOverlayFragment, props.fragment);
  const openHouses = useList(data?.openHouse);

  const getOpenHouseText = (openHouse: OpenHouse) => {
    if (openHouse?.activityType?.isRegistration) {
      return `${openHouse?.activityType?.nameOnTimeline || ''}`;
    } else {
      return `${openHouse?.activityType?.nameOnTimeline || ''} d. ${formatDate(openHouse?.startDate)}`;
    }
  };

  const isRegistrationDeadlineAfterToday = (registrationDeadline: Date) => {
    if (!registrationDeadline) {
      return true;
    }

    const deadline = new Date(registrationDeadline);
    const today = new Date();

    return isAfter(deadline, today);
  };

  if (openHouses.length === 1) {
    const openHouse = openHouses[0];
    if (openHouse?.activityType?.isRegistration) {
      if (openHouse?.isRegistred) {
        return (
          <div className="match-hero-images__open-house">
            <Block className="match-hero-images__open-house-button" flex flexGutter="xs">
              <h3 className={'overlay__text'}>{getOpenHouseText(openHouse)}</h3>
              <div className={'open-house-badge'}>
                <p>
                  <FormattedMessage defaultMessage="Registered" id="Match.Registered" />
                </p>
              </div>
            </Block>
          </div>
        );
      } else {
        return (
          <div className="match-hero-images__open-house">
            <Block className="match-hero-images__open-house-button" flex flexGutter="xs">
              <h3 className={'overlay__text'}>{getOpenHouseText(openHouse)}</h3>
              <Button
                onClick={props.handleOnClickOpenHouseModal}
                disabled={!isRegistrationDeadlineAfterToday(openHouse.registrationDeadline)}
              >
                <FormattedMessage defaultMessage="See more" id="Match.OpenHouseClick" />
              </Button>
            </Block>
          </div>
        );
      }
    } else {
      return (
        <div className="match-hero-images__open-house">
          <Block className="match-hero-images__open-house-button" flex flexGutter="xs">
            <h3 className={'overlay__text'}>{getOpenHouseText(openHouse)}</h3>
          </Block>
        </div>
      );
    }
  } else {
    const openHouse = openHouses[0];
    return (
      <div className="match-hero-images__open-house">
        <Block className="match-hero-images__open-house-button" flex flexGutter="xs">
          <h3 className={'overlay__text'}>{getOpenHouseText(openHouse)}</h3>
          <Button onClick={props.handleOnClickOpenHouseModal}>
            <FormattedMessage defaultMessage="See more" id="Match.OpenHouseClick" />
          </Button>
        </Block>
      </div>
    );
  }
};
