import React, { useEffect } from 'react';
import './bell-highlight-popup.scss';
import { FormattedMessage } from 'react-intl';
import useToggle from 'hooks/useToggle';

type Props = {
  displayPopUp: boolean;
};

const DONT_SHOW = 'dontShow';
const HIGHLIGHT_BELL = 'highlightBell';

export const BellHighlightPopup = ({ displayPopUp }: Props) => {
  const [isVisible, toggleIsVisible] = useToggle(false);

  useEffect(() => {
    setTimeout(() => {
      const localStore = localStorage.getItem(HIGHLIGHT_BELL);
      const sessionStore = sessionStorage.getItem(HIGHLIGHT_BELL);

      if (localStore === DONT_SHOW || sessionStore === DONT_SHOW) return;

      if (displayPopUp) {
        toggleIsVisible(true);
      }
    }, 60000);
  }, [displayPopUp, toggleIsVisible]);

  const handleTooltipClose = () => {
    sessionStorage.setItem(HIGHLIGHT_BELL, DONT_SHOW);
    toggleIsVisible(false);
  };

  const onDontShowAgain = () => {
    localStorage.setItem(HIGHLIGHT_BELL, DONT_SHOW);
    toggleIsVisible(false);
  };

  return (
    <div className={`bell-highlight ${isVisible ? 'bell-highlight--visible' : ''}`}>
      <div className="bell-highlight__header">
        <h1 className="bell-highlight__title">
          <FormattedMessage id="BellHighlight.header" defaultMessage="Get notifications!" />
        </h1>
        <button className="bell-highlight__close-button" onClick={handleTooltipClose}>
          &times;
        </button>
      </div>
      <div className="bell-highlight__content">
        <FormattedMessage
          id="BellHighlight.description"
          defaultMessage="By clicking the bell and selecting 'Enable push notifications', you will always stay updated with the latest news"
        />
      </div>
      <button className="bell-highlight__dont-show-button" onClick={onDontShowAgain}>
        <FormattedMessage id="PWAPrompt.dontShowAgian" defaultMessage="Don't show again" />
      </button>
    </div>
  );
};
