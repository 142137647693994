import { documentsTableRowFragment$key } from '__generated__/documentsTableRowFragment.graphql';
import Button from 'components/atoms/button/button';
import Typography from 'components/atoms/typography/typography';
import IndeterminateCheckbox from 'components/molecules/indeterminate-checkbox/indeterminate-checkbox';
import Block from 'components/utilities/block/block';
import { FC, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { formatDate } from 'tools/string-formatters';
import './documents-table.scss';

interface Props {
  rowSelection: string[];
  setRowSelection: (id?: string) => void;
  documentFragment: documentsTableRowFragment$key;
}

const documentsTableRowFragment = graphql`
  fragment documentsTableRowFragment on Document {
    id
    documentDownloadUrlCookieAuth(inline: false, previewState: FullVersion)
    propertyOverviewUrl
    documentName
    documentTypeName
    readDate
    grantedDate
  }
`;
export const DocumentsTableRow: FC<Props> = ({ documentFragment, rowSelection, setRowSelection }) => {
  const document = useFragment(documentsTableRowFragment, documentFragment);

  return (
    <tr>
      <td className="documents-table__checkbox">
        <IndeterminateCheckbox
          {...{
            checked: rowSelection.includes(document.id),
            indeterminate: false,
            onChange: () => setRowSelection(document.id),
          }}
        />
      </td>
      <td className="documents-table__cell">
        <Block flex>
          <Button
            anchorTag
            className="documents-table__cell--text"
            iconHeight={16}
            iconStart="Files"
            iconWidth={16}
            href={document.documentDownloadUrlCookieAuth || ''}
            padding={false}
            version="link"
          >
            <Typography tagStyle="bodyMedium">{document.documentName}</Typography>
          </Button>
        </Block>
      </td>
      <td className="hideMobile documents-table__cell">
        <Typography className="documents-table__cell--text" tagStyle="bodySmall">
          {document.documentTypeName}
        </Typography>
      </td>
      <td className="hideMobile documents-table__cell">
        <Typography subjectColor="success" tag="span" tagStyle="bodySmall">
          {document.readDate && (
            <Fragment>
              <FormattedMessage
                id="DocumentsTable.Read"
                defaultMessage="Read {date}"
                values={{ date: formatDate(document.readDate) }}
              />
            </Fragment>
          )}
        </Typography>
      </td>
      <td className="documents-table__cell">
        {document.grantedDate && <Typography tagStyle="bodySmall">{formatDate(document.grantedDate)}</Typography>}
      </td>
      <td className="documents-table__cell">
        <Button
          anchorTag
          className="documents-table__link"
          href={document.documentDownloadUrlCookieAuth || ''}
          padding={false}
          version="link"
        >
          <Typography tagStyle="bodyMedium">
            <FormattedMessage defaultMessage="Open" id="DocumentsTable.Open" />
          </Typography>
        </Button>
        {document.propertyOverviewUrl && (
          <>
            {' / '}
            <Button
              anchorTag
              className="documents-table__link"
              href={document.propertyOverviewUrl || ''}
              padding={false}
              version="link"
            >
              <Typography tagStyle="bodyMedium">
                <FormattedMessage id="DocumentsTable.SeeOnline" defaultMessage="See online" />
              </Typography>
            </Button>
          </>
        )}
      </td>
    </tr>
  );
};
